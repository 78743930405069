<template>
    <NuxtLayout name="default">
      <template v-slot:errors>
        <template v-if="statusCode === 404">
          <div class="error404">
            <div class="error404-content">
              <div class="heading">404</div>
              <div class="text">Страница не найдена</div>
              <nuxt-link class="button w-100" to="/">Вернуться к торгам</nuxt-link>
            </div>
          </div>
        </template>
        <template v-else>
          <p>
            <strong>{{ error.message }}</strong>
          </p>
          <p>Что-то пошло не так...</p>
        </template>
      </template>
    </NuxtLayout>
</template>

<script setup lang="ts">
useSeoMeta({
  title: 'Ошибка',
  description: 'Ошибка'
})

const error = useError();

const statusCode = error?.value?.statusCode;

// console.error(statusCode);

</script>

<style scoped>
.error404{
  background: #fff;
  padding: 32px 3rem;
  border-radius: 15px;
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.error404-content{
  font-weight: 700;
  text-align: center;
  font-size: 24px;
  position: relative;
}
.error404:before{
  content: '';
  width: 33%;
  height: 28.6%;
  background: url('~/components/layout/img/decor-orange-404.svg') right bottom / contain no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0 0 15px 0;
}
.error404-content .text{
  margin: 32px 0;
}
.error404-content .heading{
  color: #FC6337;
  font-size: 128px;
}
</style>
