import { default as aboutRbMAQKAmbhMeta } from "/var/www/frontend/pages/about.vue?macro=true";
import { default as index3aK7y6mLV5Meta } from "/var/www/frontend/pages/archive/index.vue?macro=true";
import { default as cabinetqLfhQMky9YMeta } from "/var/www/frontend/pages/cabinet.vue?macro=true";
import { default as indexZLTfQHzqvvMeta } from "/var/www/frontend/pages/confirmation/index.vue?macro=true";
import { default as contactsRJkL9fyf0xMeta } from "/var/www/frontend/pages/contacts.vue?macro=true";
import { default as index7M8kaIRfQNMeta } from "/var/www/frontend/pages/current/index.vue?macro=true";
import { default as index3dEVsDFK1VMeta } from "/var/www/frontend/pages/deals/index.vue?macro=true";
import { default as index2INVjshceQMeta } from "/var/www/frontend/pages/expomobility/index.vue?macro=true";
import { default as indexrMjMxUuH2IMeta } from "/var/www/frontend/pages/favorites/index.vue?macro=true";
import { default as indexyHBoEA6HqjMeta } from "/var/www/frontend/pages/finished/index.vue?macro=true";
import { default as helpuuIkliIu64Meta } from "/var/www/frontend/pages/help.vue?macro=true";
import { default as indexV37mLHP055Meta } from "/var/www/frontend/pages/index.vue?macro=true";
import { default as item_45_91id_93i9REcegaTbMeta } from "/var/www/frontend/pages/item-[id].vue?macro=true";
import { default as indexWuFLaCp8eYMeta } from "/var/www/frontend/pages/login/index.vue?macro=true";
import { default as index9gemHNsxBNMeta } from "/var/www/frontend/pages/my/index.vue?macro=true";
import { default as indexitynpQra7pMeta } from "/var/www/frontend/pages/politics/index.vue?macro=true";
import { default as indexA62UdR1qBWMeta } from "/var/www/frontend/pages/recovery/index.vue?macro=true";
import { default as indexxJERQI9hEFMeta } from "/var/www/frontend/pages/registration/index.vue?macro=true";
import { default as index38zRZRK80LMeta } from "/var/www/frontend/pages/rent/index.vue?macro=true";
import { default as indexeIpotXaNLOMeta } from "/var/www/frontend/pages/reset-password/index.vue?macro=true";
import { default as index8Tt5X3eRxMMeta } from "/var/www/frontend/pages/rules/index.vue?macro=true";
import { default as indexldOpvebe8JMeta } from "/var/www/frontend/pages/update-email/index.vue?macro=true";
import { default as indexDgBWdEB52eMeta } from "/var/www/frontend/pages/waiting/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/var/www/frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "archive",
    path: "/archive",
    component: () => import("/var/www/frontend/pages/archive/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet",
    path: "/cabinet",
    component: () => import("/var/www/frontend/pages/cabinet.vue").then(m => m.default || m)
  },
  {
    name: "confirmation",
    path: "/confirmation",
    meta: indexZLTfQHzqvvMeta || {},
    component: () => import("/var/www/frontend/pages/confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/var/www/frontend/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "current",
    path: "/current",
    component: () => import("/var/www/frontend/pages/current/index.vue").then(m => m.default || m)
  },
  {
    name: "deals",
    path: "/deals",
    component: () => import("/var/www/frontend/pages/deals/index.vue").then(m => m.default || m)
  },
  {
    name: "expomobility",
    path: "/expomobility",
    component: () => import("/var/www/frontend/pages/expomobility/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/var/www/frontend/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "finished",
    path: "/finished",
    component: () => import("/var/www/frontend/pages/finished/index.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/var/www/frontend/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "item-id",
    path: "/item-:id()",
    component: () => import("/var/www/frontend/pages/item-[id].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexWuFLaCp8eYMeta || {},
    component: () => import("/var/www/frontend/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "my",
    path: "/my",
    component: () => import("/var/www/frontend/pages/my/index.vue").then(m => m.default || m)
  },
  {
    name: "politics",
    path: "/politics",
    component: () => import("/var/www/frontend/pages/politics/index.vue").then(m => m.default || m)
  },
  {
    name: "recovery",
    path: "/recovery",
    meta: indexA62UdR1qBWMeta || {},
    component: () => import("/var/www/frontend/pages/recovery/index.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    meta: indexxJERQI9hEFMeta || {},
    component: () => import("/var/www/frontend/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: "rent",
    path: "/rent",
    component: () => import("/var/www/frontend/pages/rent/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexeIpotXaNLOMeta || {},
    component: () => import("/var/www/frontend/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "rules",
    path: "/rules",
    component: () => import("/var/www/frontend/pages/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "update-email",
    path: "/update-email",
    meta: indexldOpvebe8JMeta || {},
    component: () => import("/var/www/frontend/pages/update-email/index.vue").then(m => m.default || m)
  },
  {
    name: "waiting",
    path: "/waiting",
    component: () => import("/var/www/frontend/pages/waiting/index.vue").then(m => m.default || m)
  }
]